import { render } from "@testing-library/react";
import { exportExcel, exportPdf } from "../../resources/SVGImages/dashboard";
import { getDisplayDate } from "../../utils/date";

export const configuration = {
  type: "bar",
  data: {
    labels: [
      "HHG",
      "Temporary Living",
      "Home Sale",
      "Home Purchase",
      "Destination Services",
      "Storage",
    ],
    datasets: [],
  },
  options: {
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
          callback: function (value: { toLocaleString: () => string }) {
            return "$" + value.toLocaleString();
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    // legend: false
  },
};

export const exportOptions = (handleExport: any, type: any) => [
  {
    key: "7",
    label: "Export xlsx",
    icon: exportExcel,
    onclick: () => {
      handleExport(type);
    },
  },
];

export const getMapColumns = (filter: any) => {
  let data = [];

  const type = filter.map_data_filter;
  const isDomestic = filter.domestic === "true";

  data = [
    {
      title: isDomestic ? "State" : "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Origin",
      dataIndex: "origin",
      key: "origin",
    },
    {
      title: "Destination",
      dataIndex: "destination",
      key: "destination",
    },
  ];

  if (type === "Origin") {
    data = [
      {
        title: isDomestic ? "State" : "Country",
        dataIndex: "country",
        key: "country",
      },
      {
        title: "Origin",
        dataIndex: "origin",
        key: "origin",
      },
    ];
  }
  if (type === "Destination") {
    data = [
      {
        title: isDomestic ? "State" : "Country",
        dataIndex: "country",
        key: "country",
      },
      {
        title: "Destination",
        dataIndex: "destination",
        key: "destination",
      },
    ];
  }

  return data;
};

export const randomColorArray = [
  "#FF9F38",
  "#FFDF38",
  "#16AFF4",
  "#52CD9F",
  "#FF6633",
  "#8D3DAF",
  "#E07C24",
  "#E03B8B",
  "#FF6666",
  "#03C6C7",
  "#5A20CB",
  "#CAD5E2",
  "#35BDD0",
  "#5DA3FA",
  "#02B290",
  "#6EC72D",
  "#FFB399",
  "#FF33FF",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
];

const assignmentTypes = {
  LTA: "Long Term Assignment",
  STA: "Short Term Assignment",
  RA: "Rotational Assignment",
};

export const assignmentColumns = [
  {
    dataIndex: "name",
    key: "name",
    title: "Employee",
  },
  {
    dataIndex: "assignmentType",
    key: "assignmentType",
    title: "Assignment Type",
    render: (text: any) => {
      return assignmentTypes[text] || text;
    },
  },
  {
    dataIndex: "origin",
    title: "Origin",
  },
  {
    dataIndex: "destination",
    title: "Destination",
  },
  {
    dataIndex: "assignmentStartDate",
    title: "Assignment Start",
    render: (text: any) => {
      return getDisplayDate(text);
    },
  },
  {
    dataIndex: "assignmentEndDate",
    title: "Assignment Expiration",
    render: (text: any) => {
      return getDisplayDate(text);
    },
  },
  {
    dataIndex: "visaExpiryDate",
    title: "Visa Expiration",
    render: (text: any) => {
      return getDisplayDate(text);
    },
  },
];
